import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back to `}<a parentName="p" {...{
        "href": "/Case-studies",
        "title": "Case-studies"
      }}>{`Case-studies`}</a>{``}</p>
    <h3>{`New Zealand's HiveMind`}</h3>
    <p><strong parentName="p">{`Headline:`}</strong>{` National newspaper in New Zealand sets up "HiveMind", helps government agency develop policy.`}<br parentName="p"></br>{`
`}<strong parentName="p">{`When:`}</strong>{` 2016 - 2019`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Where:`}</strong>{` New Zealand`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Size:`}</strong>{` 1700+   `}</p>
    <p><strong parentName="p">{`Details:`}</strong>{` Two facilitators at New Zealand’s Public Engagement Projects (PEP) partnered with a feisty national newspaper to run six Polis conversations in as many years on issues ranging from taxation to sugar policy to Universal Basic Income. The method eventually attracted the attention of a staffer at a national government agency and the method was successfully used to help create national policy. `}</p>
    <p>{`HiveMind was an experiment that aimed to include the readers of a national newspaper in New Zealand in co-creating a more participatory and interactive public media conversation and to demonstrate that members of the public can engage productively with public issues and the news. In addition to exploring specific issues in depth, HiveMind was also used to crowdsource a set of issues to focus the newspaper's 2017 election coverage on. PEP’s issue-based work is extremely well-framed. The facilitators prepared briefing materials with distinctly articulated position statements, which were used to inform HiveMind participants and to develop ‘seed’ Polis statements. From 2016 - 2019, PEP + Scoop ran 6 national HiveMind engagements, and then through networking, connected with staff at a government agency who held the mandate to conduct a public consultation as well as the ability to outsource part of that consultation to civil society organizations. This was the genesis of the biodiversity conversation, which included controversial topics like conservation restrictions and invasive species management. Scoop’s Biodiversity HiveMind, powered by Polis, informed New Zealand’s national biodiversity strategy.`}</p>
    <h3>{`Links:`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Platform home:`}</strong>{` `}<a parentName="li" {...{
          "href": "https://info.scoop.co.nz/HiveMind"
        }}>{`https://info.scoop.co.nz/HiveMind`}</a>{` — with links to Polis reports and final reports for each topic: biodiversity, housing, tax, cannabis, universal basic income, freshwater quality, sugar`}</li>
      <li parentName="ul"><strong parentName="li">{``}<a parentName="strong" {...{
            "href": "/Facilitation-partner:-The-Public-Engagement-Project",
            "title": "Facilitation partner: The Public Engagement Project"
          }}>{`Facilitation partner: The Public Engagement Project`}</a>{``}<a parentName="strong" {...{
            "href": "https://pep.org.nz/"
          }}>{`https://pep.org.nz/`}</a></strong>{`: Simon Wright and John Pennington, New Zealanders who have been advocating for more inclusive and deliberative governance through innovations such as citizens assemblies and digital engagement since 2005. `}</li>
    </ul>
    <p><strong parentName="p">{`Media:`}</strong>{` `}</p>
    <ul>
      <li parentName="ul">{`Newspaper coverage: `}<a parentName="li" {...{
          "href": "https://www.scoop.co.nz/stories/PO2012/S00004/doc-also-tries-to-restore-e-democracy.htm"
        }}>{`https://www.scoop.co.nz/stories/PO2012/S00004/doc-also-tries-to-restore-e-democracy.htm`}</a></li>
      <li parentName="ul">{`Radio coverage: `}<a parentName="li" {...{
          "href": "http://www.radionz.co.nz/national/programmes/mediawatch/audio/201848483/getting-engaged-with-the-audience"
        }}>{`http://www.radionz.co.nz/national/programmes/mediawatch/audio/201848483/getting-engaged-with-the-audience`}</a></li>
      <li parentName="ul">{`Our blog post: `}<a parentName="li" {...{
          "href": "https://blog.pol.is/pol-is-case-study-temperature-check-a02dff7cc838"
        }}>{`https://blog.pol.is/pol-is-case-study-temperature-check-a02dff7cc838`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      